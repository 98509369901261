import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchEjectSetting = createAsyncThunk(
    "data/ejectSetting",
    async () => {
        if (window.gregario.skipFeature.includes("data/ejectSetting")) {
            return
        }
        const data = await window.gregario.getEjectSetting()
        window.gregario.globalData.ejectSetting = data?.results || []
        return data
    }
);

export const ejectngSlice = createSlice({
    name: "ejectSetting",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEjectSetting.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchEjectSetting.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
        })
        builder.addCase(fetchEjectSetting.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default ejectngSlice.reducer