import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchFormatTemplates = createAsyncThunk(
    "data/formatTemplates",
    async () => {
        if (window.gregario.skipFeature.includes("data/formatTemplates")) {
            return
        }
        const data = await window.gregario.getFormatTemplates()
        window.gregario.globalData.FormatTemplates = data?.results
        return data
    }
);

export const formatTemplates = createSlice({
    name: "formatTemplates",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchFormatTemplates.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchFormatTemplates.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
        })
        builder.addCase(fetchFormatTemplates.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default formatTemplates.reducer