import React, { Component, Suspense, lazy } from 'react';
// import { dom } from '@fortawesome/fontawesome-svg-core'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
import devConfig from './classes/dev-config.json';
import version from './autobuild_version'
import '@fortawesome/fontawesome-free/css/all.min.css';
import track from 'react-tracking';
import { UserTracking } from './classes/userTracking.class'
// library.add(fas, far)
import { getDistance } from 'geolib';

/*
window.addEventListener('postmessage', (data)=>{
  if( data.action === 'info'){
    window.gregario.usuer = info;
  }
})
*/

window.tableau_proto = process.env.NODE_ENV === 'production' ? "{{ tableau_proto }}" : process.env.REACT_APP_TABLEAU_PROTO
window.tableau_host = process.env.NODE_ENV === 'production' ? "{{ tableau_host }}": process.env.REACT_APP_TABLEAU_HOST

console.dbg = function () {
  if (process.env.NODE_ENV !== "development" && !window.gregario?.force_debug_mode) return;
};

// dom.watch()
window.app_version = version
if ((!window.frontend_site || window.frontend_site === "{{ frontend_site }}") && window.location.protocol === 'file:') {
  const urlParams = new URLSearchParams(window.location.search)
  window.frontend_site = urlParams.get('frontend_site')
}
if ((!window.googleAPIKey || window.googleAPIKey === "{{ google_api_key }}") && window.location.protocol === 'file:') {
  const urlParams = new URLSearchParams(window.location.search)
  window.googleAPIKey = urlParams.get('googleAPIKey')
}

if ( window.googleGeolocationAPIKey === "{{ google_geolocation_api_key }}") {
  window.googleGeolocationAPIKey = null
}
if (window.oneSignalAppId === "{{ onesignal_app_id }}" ){
  window.oneSignalAppId = null
}
if (window.frontend_site !== undefined && window.frontend_site.match(/{{.*}}/)) {
  window.frontend_site = undefined;
}
if (window.frontend_site === undefined && devConfig.hasOwnProperty('template')) {
  window.frontend_site = devConfig.template
}

if (window.connection !== undefined && !window.connection.match(/{{.*}}/)) {
  window.connection = decodeURIComponent(window.atob(window.connection));
  window.connection = JSON.parse(window.connection);
} else {
  window.connection = undefined;
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadMainClass: false,
    }
  }

  visibilitychange(e) {
    //Acts only when the document is visible
    if (document.visibilityState !== 'visible') {
      return
    }
    // here add all the functions that are needed to execute when the app is in focus
    if (window.gregario) {
      window.gregario.requestDeviceInfo()
      window.gregario.checkForUpdates();
      window.gregario.downloadRoute();
    }
  }

  componentDidMount() {
    import(`./templates/${window.frontend_site}/classes/gregario.class`).then(imp => {
      if (!window.gregario) {
        window.gregario = new imp.GREGARIO()
      }
      if (!document.cookie) {
        window.gregario.api.request.fetch('get-cookie-token', null, { useCache: false }).then(cookie_token => {
          if (cookie_token) {
            window.gregario.cookie_token = cookie_token['token']
          }
        })
      }

      if (!window.gregario.visibilityChange) {
        window.gregario.visibilityChange = document.addEventListener("visibilitychange", (e) => this.visibilitychange(e));
      }

      if (navigator.geolocation) {
        if (!window.gregario.watchPosition) {
          window.gregario.watchPosition = navigator.geolocation.watchPosition(function (position) {
            if (window.gregario) {
              let current_lat = window.gregario.location.latitude
              let current_lng = window.gregario.location.longitude
              window.gregario.location.latitude = position.coords.latitude;
              window.gregario.location.longitude = position.coords.longitude;
              if (!window.ReactNativeWebView?.postMessage) {
                let from = { latitude: current_lat, longitude: current_lng }
                if (window.gregario.lastTrackingLocation) {
                  from = {
                    latitude: window.gregario.lastTrackingLocation.latitude,
                    longitude: window.gregario.lastTrackingLocation.longitude,
                  }
                }
                let to = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                let distance = getDistance(from, to)
                if (distance >= 20) {
                  let trackInfo = {
                    coords: {
                      accuracy: position.coords.accuracy,
                      altitude: position.coords.altitude,
                      altitudeAccuracy: position.coords.altitudeAccuracy,
                      heading: position.coords.heading,
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude,
                      speed: position.coords.speed,
                    },
                    timestamp: position.timestamp,
                    origin: 'web_app',
                  }
                  window.gregario.sendTrackingData(trackInfo).then(() => {
                    window.gregario.lastTrackingLocation = {
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude
                    }
                  })
                }
              }
            }
          }, () => {}, {
            enableHighAccuracy: true,
            maximumAge: 300000,
            timeout: 45000,
          });
        }
      }
      this.setState({loadMainClass: true})
    })

    if (window.ReactNativeWebView !== undefined) {
      setInterval(() => {
        window.ReactNativeWebView?.postMessage && window.ReactNativeWebView.postMessage(JSON.stringify({ command: 'ping' }));
        let root = document.getElementById('root')
        if (!root.innerHTML && window.ReactNativeWebView?.postMessage) {
          window.ReactNativeWebView.postMessage(JSON.stringify({ command: 'fail' }));
        }
      }, 3000);
    }
  }

  /**
   * Function used to determine which template should be shown
   */
  selectTemplate() {
    if (window.frontend_site) {
        //return lazy(() => import('./templates/common/MainApp'));
        return lazy(() => import(`./templates/${window.frontend_site}/MainApp`))
    } else {
      //return lazy(() => import('./templates/gregario/MainApp'));
      return lazy(() => import('./templates/common/MainApp'));
    }
  }

  /**
   * render
   */
  render() {
    let MainApp = null
    if (window.gregario) {
      MainApp = this.selectTemplate()
    }
    return (
      <Suspense fallback={<div>Loading...</div>}>
        {
          this.state.loadMainClass && <MainApp />
        }
      </Suspense>
    )
  }
}

export default track({},
  { dispatch: data => {
    if(Object.keys(data).length === 0){
      return
    }
    let userTracking = new UserTracking(data)
    userTracking.send()
  } }
)(App)